import * as Dialog from '@radix-ui/react-dialog';
import React, { useCallback, useMemo, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useHistory } from 'react-router-dom';
import { hideModal } from 'shared/foreground/actions/modals';
import { saveLinkToReader } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { addFeed } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import getUIFriendlyNameForDocumentLocation from 'shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import { useWisereadsItems } from 'shared/foreground/utils/useWisereadsItems';
import { DocumentLocation } from 'shared/types';
import { WisereadItem } from 'shared/types/wisereads';
import urlJoin from 'shared/utils/urlJoin';
import { wisereadsCategoriesToDisplayNames } from 'shared/utils/wisereads';

import useIsModalShown from '../../utils/useIsModalShown';
import Button from '../Button';
import CircleCheckIcon from '../icons/20SolidCircleCheck';
import ChevronDownIcon from '../icons/20StrokeChevronDownSmall';
import CloseIcon from '../icons/CloseIcon';
import StrokeAddCircleIcon from '../icons/StrokeAddCircleIcon';
import Spinner from '../Spinner';
import styles from './AddWisereadsItemsModal.module.css';
import Modal from './Modal';

const modalId = 'add-wisereads-items';

const WisereadItemCard = ({ item, addItemToReader, isAlreadySaved }: { item: WisereadItem; addItemToReader: (url: string) => void; isAlreadySaved: boolean; }) => {
  const itemContent = useMemo(() => {
    // Replace hardcoded highlight color coming from the backend with CSS variable to support dark mode.
    return item.content.replace(/#fbeeb8/g, 'var(--highlight-background-color--active)');
  }, [item.content]);

  const addItemToReaderHandler = useCallback(() => {
    if (isAlreadySaved) {
      return;
    }

    addItemToReader(item.target_url);
  }, [addItemToReader, item.target_url, isAlreadySaved]);

  return (
    <button
      className={styles.card}
      onClick={addItemToReaderHandler}
      type="button"
      style={{
        pointerEvents: isAlreadySaved ? 'none' : 'auto',
      }}
    >
      <div className={styles.imageContainer}>
        <img
          src={item.cover_image}
          alt={item.title}
          className={styles.coverImage}
        />
      </div>
      <div className={styles.cardContent}>
        <div className={styles.publicationContainer}>
          {item.publication_icon && <img className={styles.publicationIcon} src={item.publication_icon} alt={item.publication ?? ''} />}
          {item.publication && <span className={styles.publicationName}>{item.publication}</span>}
        </div>
        <h2 className={styles.title}>{item.title}</h2>
        <div className={styles.meta}>
          <span className={`${styles.truncate} ${styles.author}`}>{item.author}</span>
          <span className={styles.dot}>•</span>
          <span className={styles.time}>14 mins</span>
        </div>
        <p className={styles.content} dangerouslySetInnerHTML={{ __html: itemContent }} />
        <Button variant="primary" className={styles.addToReaderButton} disabled={isAlreadySaved}>
          {isAlreadySaved ? <CircleCheckIcon className={styles.addIcon} /> : <StrokeAddCircleIcon className={styles.addIcon} />}
          {isAlreadySaved ? 'Saved' : `Add ${wisereadsCategoriesToDisplayNames[item.category]} to Reader`}
        </Button>
      </div>
    </button>
  );
};

function Header() {
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const hidePopover = useCallback(() => setIsPopoverShown(false), []);
  const showPopover = useCallback(() => setIsPopoverShown(true), []);

  const addWisereadsRss = useCallback(() => {
    addFeed({
      url: 'https://wise.readwise.io/feed',
    }, {
      toastContent: 'Added Wisereads RSS',
      userInteraction: 'click',
    });
  }, []);

  return (
    <header className={styles.modalHeader}>
      <Dialog.Title className={styles.titleContainer}>
        <span className={styles.title}>Latest from Wisereads</span>
        <span
          className={styles.learnMore}
          onMouseEnter={showPopover}
          onMouseLeave={hidePopover}
        >
          Learn more <ChevronDownIcon />
          {isPopoverShown &&
            <>
              <div className={styles.popoverBridge} />
              <div className={styles.learnMorePopoverContainer}>
                <p>Wisereads is a weekly aggregation of the most highlighted documents in Reader.</p>
                <p>While not specifically curated by our team or personalized to your tastes, we think these are great fodder for filling up your Reader account with something to read quickly.</p>
                <p>You can subscribe to Wisereads in Reader below.</p>
                <Button variant="primary" className={styles.addToReaderButton} onClick={addWisereadsRss}>
                  <StrokeAddCircleIcon className={styles.addIcon} />
                  Add Wisereads RSS to Reader
                </Button>
              </div>
            </>
          }
        </span>
      </Dialog.Title>
      <Dialog.Close asChild>
        <Button
          className={styles.closeButton}
          onClick={() => hideModal({ id: modalId }, { userInteraction: 'click' })}
          variant="unstyled"
        >
          <CloseIcon />
        </Button>
      </Dialog.Close>
    </header>
  );
}

export default function AddWisereadsItemsModal() {
  const isShown = useIsModalShown(modalId);
  const documentLocations = useDocumentLocations();
  const [savedUrls, setSavedUrls] = useState(new Set<string>());
  const history = useHistory();
  const {
    hasNext,
    items,
    isInitialLoading,
    isLoadingNextPage,
    setCurrentPage,
  } = useWisereadsItems({
    isEnabled: isShown,
    initialCurrentPage: 1,
  });

  const goToNewDoc = useCallback(
    (docId: string) => {
      history.push(urlJoin(['/', DocumentLocation.New, 'read', docId]));
      hideModal({ id: modalId }, { userInteraction: 'click' });
    },
    [history],
  );

  const addItemToReader = useCallback(async (url: string) => {
    await saveLinkToReader({
      newDocumentLocation: documentLocations[0],
      onButtonClick: goToNewDoc,
      tooltipContent: `Link saved to ${getUIFriendlyNameForDocumentLocation(documentLocations[0])}`,
      url,
    });
    setSavedUrls((previousState) => new Set([...previousState, url]));
  }, [goToNewDoc, documentLocations]);

  const onContentScroll = useCallback((scrollEvent) => {
    if (isLoadingNextPage || !hasNext) {
      return;
    }

    const scrollContainer = scrollEvent.target;
    const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

    if (scrollHeight - scrollTop <= clientHeight + 800) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [hasNext, isLoadingNextPage, setCurrentPage]);

  const mainContent = useMemo(() => {
    if (isInitialLoading) {
      return <>
        <Header />
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      </>;
    }

    return (
      <>
        <Header />
        <div className={`${styles.contentContainer} has-visible-scrollbar`} onScroll={onContentScroll}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              /* eslint-disable @typescript-eslint/naming-convention */
              370: 1, // 1 column up to 370px
              790: 2, // 2 columns when width allows 370-420px per column
              1210: 3, // 3 columns when width allows 370-420px per column
              1630: 4, // 4 columns when width allows 370-420px per column
              2050: 5, // 5 columns when width allows 370-420px per column
              /* eslint-enable */
            }}
          >
            <Masonry gutter="20px">
              {items.map((item) =>
                <WisereadItemCard
                  key={item.target_url}
                  item={item}
                  addItemToReader={addItemToReader}
                  isAlreadySaved={savedUrls.has(item.target_url)}
                  />)}
              {isLoadingNextPage && <div className={styles.loadingNextPageContainer}><Spinner /></div>}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </>
    );
  }, [isInitialLoading, isLoadingNextPage, items, onContentScroll, savedUrls, addItemToReader]);

  return <Modal
    id={modalId}
    rootClassName={styles.modalRoot}
    mainContentClassName={styles.mainContentClassName}
    mainContent={mainContent}
    title="Latest from Wisereads"
    showHeader={false}
  />;
}
