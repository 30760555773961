import { useEffect, useState } from 'react';

import { WisereadItem, WisereadsIssue } from '../../types/wisereads';
import request from '../../utils/request';

export const useWisereadsItems = ({ isEnabled, initialCurrentPage }: { isEnabled: boolean; initialCurrentPage: number; }) => {
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [hasNext, setHasNext] = useState(false);
  const [items, setItems] = useState<WisereadItem[]>([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      if (currentPage === 1) {
        setIsInitialLoading(true);
      } else {
        setIsLoadingNextPage(true);
      }

      const response = await request(`https://wise.readwise.io/wisereads_documents?page=${currentPage}`);
      const data = await response.json();

      const issues: WisereadsIssue[] = data.issues;
      setItems((prev) => prev.concat(issues.map((issue) => issue.documents).flat()));
      setHasNext(data.pagination.has_next);
      setIsInitialLoading(false);
      setIsLoadingNextPage(false);
    };

    if (isEnabled) {
      fetchItems();
    }
  }, [isEnabled, currentPage]);


  useEffect(() => {
    if (isEnabled) {
      return;
    }

    setCurrentPage(initialCurrentPage);
    setItems([]);
    setHasNext(false);
    setIsInitialLoading(true);
    setIsLoadingNextPage(false);
  }, [initialCurrentPage, isEnabled]);

  return {
    hasNext,
    items,
    isInitialLoading,
    isLoadingNextPage,
    setCurrentPage,
  };
};
